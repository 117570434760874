import { isPlainObject, isFunction } from 'lodash';

/*!
 * This is rewrite of the original jQuery hoverIntent plugin.
 * For more info check out: https://github.com/briancherne/jquery-hoverIntent
 */

/* eslint-disable no-param-reassign, no-else-return */

const _cfg = {
  interval: 100,
  sensitivity: 6,
  timeout: 0
};
let INSTANCE_COUNT = 0;
let cX;
let cY;
const track = (ev) => {
  cX = ev.pageX;
  cY = ev.pageY;
};

const eventsMap = new Map();

const compare = (ev, el, s, cfg) => {
  if (Math.sqrt((s.pX - cX) * (s.pX - cX) + (s.pY - cY) * (s.pY - cY)) < cfg.sensitivity) {
    el.removeEventListener(s.event, track);
    delete s.timeoutId;
    s.isActive = true;
    ev.pageX = cX;
    ev.pageY = cY;
    delete s.pX;
    delete s.pY;
    return cfg.over.apply(el, [ev]);
  } else {
    s.pX = cX;
    s.pY = cY;
    s.timeoutId = setTimeout(() => compare(ev, el, s, cfg), cfg.interval);
  }
  return true;
};
const delay = (ev, el, s, out) => {
  eventsMap.delete(`hoverIntent_${s.id}`);
  return out.apply(el, [ev]);
};

export const hoverIntent = (elements, handlerIn, handlerOut, selector) => {
  const instanceId = INSTANCE_COUNT++;

  let cfg = Object.assign({}, _cfg);
  if (isPlainObject(handlerIn)) {
    cfg = Object.assign(cfg, handlerIn);
    if (!isFunction(cfg.out)) {
      cfg.out = cfg.over;
    }
  } else if (isFunction(handlerOut)) {
    cfg = Object.assign(cfg, { over: handlerIn, out: handlerOut, selector });
  } else {
    cfg = Object.assign(cfg, { over: handlerIn, out: handlerIn, selector: handlerOut });
  }

  const handleHover = function handleHover(e) {
    const ev = Object.assign({}, e);

    const { target } = e;

    const hoverIntentData = eventsMap.has(`hoverIntent_${instanceId}`);
    if (!hoverIntentData) {
      eventsMap.set(`hoverIntent_${instanceId}`, { id: instanceId, event: e.type });
    }

    let state = eventsMap.get(`hoverIntent_${instanceId}`);
    if (!state) {
      state = { id: instanceId };
      eventsMap.set(`hoverIntent_${instanceId}`, { id: instanceId, event: e.type });
    }

    if (state.timeoutId) {
      state.timeoutId = clearTimeout(state.timeoutId);
    }

    const mousemove = state.event = 'mousemove'; // eslint-disable-line

    if (e.type === 'mouseenter') {
      if (state.isActive) {
        return;
      }
      state.pX = ev.pageX;
      state.pY = ev.pageY;
      target.removeEventListener(mousemove, track);
      target.addEventListener(mousemove, track);
      state.timeoutId = setTimeout(() => compare(ev, target, state, cfg), cfg.interval);
    } else {
      if (!state.isActive) {
        return;
      }
      target.removeEventListener(mousemove, track);
      state.timeoutId = setTimeout(() => delay(ev, target, state, cfg.out), cfg.timeout);
    }
  };

  return {
    add: () => {
      [].forEach.call(elements, element => {
        element.addEventListener('mouseenter', handleHover);
        element.addEventListener('mouseleave', handleHover);
      });
    },
    remove: () => {
      [].forEach.call(elements, element => {
        element.removeEventListener('mouseenter', handleHover);
        element.removeEventListener('mouseleave', handleHover);
      });
    }
  };
};
