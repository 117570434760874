export default (() => {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }
  function CustomEvent(event, params) {
    const customParams = params || { bubbles: false, cancelable: false, detail: undefined };
    const customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent(event, customParams.bubbles, customParams.cancelable, customParams.detail);
    return customEvent;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
  return true;
})();
