import { hoverIntent } from './hoverIntent';
import { isElementVisible, fadeIn, fadeOut, topWrapperOffset, usernamePadding, getElementAbsoluteHeights, detectIE } from 'app/utils/helpers';
import { accordionTransitionEffect } from 'app/utils/accordionTransition';
import mediaQueries from 'app/utils/mediaQueries';
import { OPEN_SEARCH } from './headerConstants';

const { pageType } = window.inlineGlobalConfig;

const fadeSpeed = 200;
const isIPhone = navigator.userAgent.match(/(iPhone|iPad)/);
const fadeTime = 700;
const delayTimeToPreventIOSBug = isIPhone ? fadeTime : 0;
let idOfFadeCompleteTimeOut;
const menuActiveClass = 'is-active';

const navigationMenu = document.querySelector('.menu-level-one');
const navigationMenuTitles = document.querySelectorAll('.menu-level-one > li > a');
const navigationAccordion = document.querySelectorAll('.menu-level-one__item--has-children');
const navigationAccordionTitles = document.querySelectorAll('.menu-level-one__item--has-children > a');
const navigationContent = document.querySelectorAll('.menu-level-one__item--has-children .content');
const menuItems = document.querySelectorAll('.js-betternet-logo');

const menuButton = document.querySelector('.js-menu');
const menuCloseButton = document.querySelector('.menu-btn__close-menu');
const menuOverlay = document.querySelector('.header__nav .menu__overlay');
let topOffset = window.pageYOffset;

const searchButton = document.querySelector('.nav-btn__search');
const searchOverlay = document.querySelector('.search__overlay');
const searchPanel = document.querySelector('.global-header__search-container');

const escCode = 27;
const navigationOffsetIE = 70;
const navigationNegativeOffsetIE = 30;
const navigationOffset = 100;

let isLargeUp = mediaQueries.is_large_up();
let accordionHandler;
accordionHandler = accordionTransitionEffect(navigationAccordionTitles);

/* eslint-disable no-param-reassign */
const addOpacityColorToBetterwareLogo = () => {
  menuItems.forEach((item) => {
    item.className = `${item.className} opacity-color`;
  });
};

const removeopacityColorToBetterwareLogo = () => {
  menuItems.forEach((item) => {
    item.className += item.className.split('opacity-color').reduce((newClass, currentClass) => {
      if (currentClass !== '') {
        return currentClass;
      }

      return null;
    }, '');
  });
};
/* eslint-enable */

let previouslySelectedTapMenuItem = null;
const onDoubleTapMenu = (e) => {
  const element = e.currentTarget;
  if (!parseInt(element.dataset.clicks, 10) && !isElementVisible(element.nextElementSibling)) {
    e.preventDefault();
    element.dataset.clicks = 1;
    if (previouslySelectedTapMenuItem) {
      previouslySelectedTapMenuItem.dataset.clicks = 0;
      previouslySelectedTapMenuItem.nextElementSibling.style.display = 'none';
      element.nextElementSibling.style.display = 'block';
    }
    previouslySelectedTapMenuItem = e.currentTarget;
  }
};

const doubleTapMenu = (elements, toggle) => {
  if (toggle === 'on') {
    [].forEach.call(elements, element => (
      element.addEventListener('click', onDoubleTapMenu)
    ));
  } else if (toggle === 'off') {
    [].forEach.call(elements, element => (
      element.removeEventListener('click', onDoubleTapMenu)
    ));
  }
};

let currentlySelected = null;
let previouslySelected = null;
function mouseEnterMenuItem() {
  currentlySelected = this;
  if (currentlySelected !== null && currentlySelected.parentNode.classList.contains('menu-level-one__item--has-children')) {
    if (previouslySelected !== null && previouslySelected.parentNode.classList.contains('menu-level-one__item--has-children')) {
      previouslySelected.nextElementSibling.style.display = 'none';
      currentlySelected.nextElementSibling.style.display = 'block';
      menuOverlay.style.display = 'block';
    } else {
      fadeIn(currentlySelected.nextElementSibling, fadeSpeed);
      fadeIn(menuOverlay, fadeSpeed);
    }
  } else {
    if (previouslySelected) {
      fadeOut(previouslySelected.nextElementSibling, fadeSpeed);
      fadeOut(menuOverlay, fadeSpeed);
    }
    currentlySelected = null;
    previouslySelected = null;
  }
  window.objectFitPolyfill();
}
function mouseLeaveMenuItem() {
  if (currentlySelected !== null && currentlySelected.parentNode.classList.contains('menu-level-one__item--has-children')) {
    previouslySelected = currentlySelected;
    currentlySelected = this;
  }
}

const hoverIntentHandler = hoverIntent(navigationMenuTitles, mouseEnterMenuItem, mouseLeaveMenuItem);

const toggleChildrenClass = (elementsClass, childrenClass, classToAdd, action) => {
  const childrens = document.querySelector(`.${elementsClass}`).children;
  [].forEach.call(childrens, element => (
    (element.classList.contains(childrenClass[0]) || element.classList.contains(childrenClass[1])) && action !== 'remove' ?
      element.classList.add(classToAdd) :
      element.classList.remove(classToAdd)
  ));
};

function closeSearch() {
  clearTimeout(idOfFadeCompleteTimeOut);
  document.querySelector('.autosuggest').classList.remove('active');
  if (!mediaQueries.is_large_up()) {
    topWrapperOffset('close', topOffset, 'nav__no-scroll');
  }

  searchButton.classList.remove(menuActiveClass);
  searchButton.setAttribute('aria-label', 'search');

  searchPanel.classList.remove(menuActiveClass);
  ['nav__account', 'nav__bag', 'nav__menu'].forEach(element =>
    toggleChildrenClass(element, ['nav-btn', 'menu-btn'], 'opacity-color', 'remove')
  );
  if (mediaQueries.is_large_up()) {
    hoverIntentHandler.add();
    [].forEach.call(navigationMenuTitles, element => element.classList.remove('opacity-color'));
  }
  removeopacityColorToBetterwareLogo();
  fadeOut(searchOverlay, fadeSpeed, () => {
    const event = new CustomEvent('searchSectionClosed');
    document.querySelector('.search-box-component').dispatchEvent(event);
  });
  doubleTapMenu(navigationAccordionTitles, 'on');
}

const onEscapeCallback = (evt) => {
  if (evt.keyCode === escCode) {
    closeSearch();
  }
  document.removeEventListener('keyup', onEscapeCallback);
};

const closeMenu = () => {
  menuButton.classList.remove(menuActiveClass);
  fadeOut(menuOverlay, fadeSpeed);
  fadeOut(menuCloseButton, fadeSpeed);
  document.querySelector('html').classList.remove('nav__no-scroll');
  document.querySelector('#wrapper').style.removeProperty('margin-top');
  window.scrollTo(0, topOffset);
};

function addClassAfterDelayToPreventIOSBug(element, className) {
  idOfFadeCompleteTimeOut = setTimeout(() => {
    element.classList.add(className);
  }, delayTimeToPreventIOSBug);
}

// TODO: remove if statement when rearrange header bundle

menuButton.addEventListener('click', (event) => {
  const element = event.currentTarget;
  element.classList.add(menuActiveClass);
  fadeIn(menuOverlay, fadeSpeed);
  fadeIn(menuCloseButton, fadeSpeed);
  if (searchPanel.classList.contains(menuActiveClass)) {
    closeSearch();
  }
  topOffset = window.pageYOffset;
  document.querySelector('html').classList.add('nav__no-scroll');
  document.querySelector('#wrapper').style.marginTop = `-${topOffset}px`;
});

menuOverlay.addEventListener('click', closeMenu);

menuCloseButton.addEventListener('click', closeMenu);

searchButton.addEventListener('click', (e) => {
  const element = e.currentTarget;
  const autosuggest = document.querySelector('.autosuggest');
  if (!autosuggest) {
    return;
  }
  if (!element.classList.contains(menuActiveClass)) {
    addClassAfterDelayToPreventIOSBug(autosuggest, 'active');
    topOffset = window.pageYOffset;
    document.querySelector('.bootstrap-typeahead-input-main').focus();
    if (isIPhone || !topOffset) {
      window.scrollTo(0, 0);
    }
    element.classList.add(menuActiveClass);
    if (!mediaQueries.is_large_up()) {
      document.querySelector('html').classList.add('nav__no-scroll');
      document.querySelector('#wrapper').style.marginTop = `-${topOffset}px`;
    }
    searchPanel.classList.add(menuActiveClass);
    fadeIn(searchOverlay, fadeSpeed);
    searchButton.setAttribute('aria-label', 'close search');
    ['nav__account', 'nav__bag', 'nav__menu'].forEach(el => toggleChildrenClass(el, ['nav-btn', 'menu-btn'], 'opacity-color'));
    if (mediaQueries.is_large_up()) {
      hoverIntentHandler.remove();
      [].forEach.call(navigationMenuTitles, navigationTitle => navigationTitle.classList.add('opacity-color'));
      doubleTapMenu(navigationAccordionTitles, 'off');
    }
    addOpacityColorToBetterwareLogo();
    document.addEventListener('keyup', onEscapeCallback);

    const event = new CustomEvent(OPEN_SEARCH);
    document.dispatchEvent(event);
  } else {
    element.classList.remove(menuActiveClass);
    closeSearch();
  }
});

searchOverlay.addEventListener('click', closeSearch);


// Anchor replacement
const hrefDirect = [];
const hrefTarget = [];
[].forEach.call(navigationMenuTitles, element => {
  if (element.dataset.url) {
    hrefDirect.push(element.dataset.url);
    hrefTarget.push(element.getAttribute('href'));
  }
});

const replaceAnchorWith = (elements, ref) => {
  let menuItemsCount = 0;
  [].forEach.call(elements, element => {
    if (element.dataset.url && element.parentNode.classList.contains('menu-level-one__item--has-children')) {
      if (ref === 'data') {
        element.setAttribute('data-url', hrefDirect[menuItemsCount]);
        element.setAttribute('href', hrefTarget[menuItemsCount]);
      } else if (ref === 'url') {
        element.setAttribute('data-url', hrefTarget[menuItemsCount]);
        element.setAttribute('href', hrefDirect[menuItemsCount]);
      }
      menuItemsCount++;
    }
  });
};

const mouseLeaveOverlay = () => {
  previouslySelectedTapMenuItem = null;
  [].forEach.call(navigationAccordionTitles, element => element.setAttribute('data-clicks', 0));
  [].forEach.call(navigationContent, element => (
    isElementVisible(element) ?
      fadeOut(element, fadeSpeed) :
      false
  ));
  fadeOut(menuOverlay, fadeSpeed);
  currentlySelected = null;
  previouslySelected = null;
};

function accordionSwitch() {
  if (mediaQueries.is_large_up()) {
    // Reset mobile menu behaviour on resize
    menuButton.classList.remove(menuActiveClass);
    menuCloseButton.style.display = 'none';
    [].forEach.call(navigationAccordion, element => element.classList.remove('active'));
    [].forEach.call(navigationContent, element => {
      element.style.removeProperty('opacity');
      element.style.removeProperty('display');
      element.setAttribute('aria-hidden', true);
    });
    menuOverlay.style.display = 'none';
    topWrapperOffset('close', topOffset, 'nav__no-scroll');

    // Equal height navigation background
    [].forEach.call(navigationContent, element => element.style.removeProperty('height'));
    const navigationContentHeights = getElementAbsoluteHeights(navigationContent);
    const navigationContentMaxHeight = Math.max.apply(null, navigationContentHeights);
    const navigationContentOffset = detectIE() ? navigationOffsetIE : navigationOffset;
    [].forEach.call(navigationContent, element => {
      const elementHeight = navigationContentMaxHeight + navigationContentOffset + (detectIE() ? navigationNegativeOffsetIE : 0);
      element.setAttribute('style', `height: ${elementHeight}px`);
    });

    // Delay menu hover
    hoverIntentHandler.add();

    navigationMenu.addEventListener('mouseleave', mouseLeaveOverlay);

    doubleTapMenu(navigationAccordionTitles, 'on');
    replaceAnchorWith(navigationMenuTitles, 'url');
  } else {
    // Reset desktop menu behaviour on resize
    [].forEach.call(navigationMenuTitles, element => element.style.removeProperty('height'));
    [].forEach.call(navigationContent, element => element.style.removeProperty('height'));
    hoverIntentHandler.remove();
    navigationMenu.removeEventListener('mouseleave', mouseLeaveOverlay);
    doubleTapMenu(navigationAccordionTitles, 'off');

    accordionHandler.add();
    replaceAnchorWith(navigationMenuTitles, 'data');
  }
}

// TODO: remove if statement when rearrange header bundle
if (pageType !== 'NonePage') {
  accordionSwitch();

  window.addEventListener('resize', () => {
    if (isLargeUp !== mediaQueries.is_large_up()) {
      isLargeUp = mediaQueries.is_large_up();
      accordionHandler.remove();
      if (!isLargeUp) {
        accordionHandler = accordionTransitionEffect(navigationAccordionTitles);
      }
    }
    accordionSwitch();
    usernamePadding();
  });
}
